import { CustomerJiraDetails, JiraProjectMapping } from "./JiraIntegration";
import { NotificationSettings } from "./Notifications";

type PrimaryContact = {
  name: string;
  email: string;
  role: string;
};

export interface SlaConfig {
  critical: number;
  high: number;
  medium: number;
  low: number;
  info: number;
}

export const DefaultSlaConfig: SlaConfig = {
  critical: 7,
  high: 30,
  medium: 90,
  low: 180,
  info: 180,
};

export interface EffortEstimationSettings {
  [key: string]: number;
  // asset: number;
  // login: number;
  // sso: number;
  // signup: number;
  // password_reset: number;
  // users_mgmt: number;
  // file_upload: number;
}

export type SlackDetails = {
  channel_id: string;
  bot_id: string;
  team_id: string;
  enterprise_id: string;
};

export type CloudFlareDetails = {
  api_key?: string;
  enabled: boolean;
};

export type AwsDetails = {
  enabled: boolean;
  access_key_id?: string;
  secret_access_key?: string;
  region_name: string;
};

export type OktaDetails = {
  org_url: string;
  client_id: string;
  secret?: string;
};

export type LicenseType =
  | "demo"
  | "trial"
  | "free_poc"
  | "admin"
  | "annual"
  | "ir_poc"
  | "out_of_contract"
  | "ciso_only"
  | "reporting_only"
  | "poc"
  | "annual_ciso"
  | "hourly"
  | "";

export const NON_RELEVANT_LICENSE = ["admin", "out_of_contract"];

export type License = {
  activation_date: string;
  expiration_date: string;
  type: LicenseType;
};

export type PriorityTier = "top" | "medium" | "low" | "no tier";
export type AccessLevel = "REGULAR" | "MSSP_MANAGER";

export type AsmConfig = {
  custom_user_agent: string;
  scan_window_end: string;
  scan_window_start: string;
};

export const DEFAULT_SCAN_WINDOW_END = "06:00";
export const DEFAULT_SCAN_WINDOW_START = "22:00";

export const IRRELEVANT_CUSTOMER_LICENSES: string[] = [
  "out_of_contract",
  "admin",
  "reporting_only",
];

export interface Customer {
  id: number;
  primary_contact?: PrimaryContact | null;
  slack_settings?: NotificationSettings | null;
  slack_details?: SlackDetails | null;
  cloudflare_details?: CloudFlareDetails | null;
  aws_details?: AwsDetails | null;
  okta_info?: OktaDetails | null;
  jira_details?: CustomerJiraDetails | null;
  jira_projects_mapping?: JiraProjectMapping[];
  sla_config?: SlaConfig | null;
  asm_project_jira_key?: string | null;
  disabled_scanners: string[];
  name: string;
  created_at: string;
  updated_at: string;
  logo_file?: string | null;
  is_slack_installed: boolean;
  is_asm_enabled: boolean;
  coverage_score?: number | null;
  auto_scan_new_assets?: boolean;
  effort_estimation_settings?: EffortEstimationSettings;
  onboarding_stage?: number | null;
  license?: License;
  is_trial_approved?: boolean;
  is_workato_connected?: boolean;
  is_multi_tenant?: boolean;
  asm_config: AsmConfig | null;
  assets_verification_token: string;
  is_locked?: boolean;
  priority_tier?: PriorityTier;
  mssp_tenant?: number;
}

import React, { useContext } from "react";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { Flex } from "../../../components/layouts/flex/Flex";
import { ThemeContext } from "styled-components";
import {
  BodyRegular,
  HeaderSecondary,
  HeaderSubBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { BadgesLine } from "../../../components/elements/badge/BadgesLine";
import { Dropdown } from "../../../components/elements/dropdowns/Dropdown";
import { useApiUpdateCustomer } from "../../../hooks/queries/customersContext";
import { useApiMe } from "../../../hooks/queries/meContext";
import useToastContext from "../../../hooks/toastHook";
import {
  DEFAULT_SCAN_WINDOW_END,
  DEFAULT_SCAN_WINDOW_START,
} from "../../../types/Customer";
import { sleep } from "../../../hooks/queries/utils";

type Props = {
  onClose: () => void;
};

export const ScanningWindowsPane = (props: Props) => {
  const { onClose } = props;
  const theme = useContext(ThemeContext);
  const { data: me } = useApiMe();
  const addToast = useToastContext();
  const {
    mutate: updateCustomer,
    status: queryStatus,
    reset: resetUpdateCustomer,
  } = useApiUpdateCustomer();
  console.log(queryStatus);

  return (
    <RightPane onClose={onClose} width="462px">
      <Flex column gap="24px" h100 style={{ overflow: "scroll" }}>
        <Flex column padding="24px 24px 0 24px">
          <LabelRegular style={{ color: theme.black600 }}>
            Scanning Policy
          </LabelRegular>
          <HeaderSecondary>Scheduled Scans</HeaderSecondary>
        </Flex>
        <SeparatorHorizontal />
        <Flex column padding="0 24px" gap="16px">
          <HeaderSubBold>Allowed scan windows</HeaderSubBold>
          <Flex w100 justify="between" gap="12px">
            <Flex w100 column gap="8px">
              <Flex>
                <LabelRegular>Start scanning from</LabelRegular>
              </Flex>
              <Dropdown
                variant="border"
                size="medium"
                value={
                  me?.customer.asm_config?.scan_window_start
                    ? {
                        label: me?.customer.asm_config?.scan_window_start,
                        value: me?.customer.asm_config?.scan_window_start,
                      }
                    : {
                        label: DEFAULT_SCAN_WINDOW_START,
                        value: DEFAULT_SCAN_WINDOW_START,
                      }
                }
                options={Array.from({ length: 24 }, (_, hour) => {
                  return {
                    label: `${hour.toString().padStart(2, "0")}:00`,
                    value: `${hour.toString().padStart(2, "0")}:00`,
                  };
                })}
                onChange={(opt) => {
                  if (opt?.value) {
                    updateCustomer({
                      customerId: me?.customer.id || 0,
                      customerData: {
                        asm_config: {
                          custom_user_agent:
                            me?.customer.asm_config?.custom_user_agent || "",
                          scan_window_end:
                            me?.customer.asm_config?.scan_window_end ||
                            DEFAULT_SCAN_WINDOW_END,
                          scan_window_start:
                            (opt?.value as string) || DEFAULT_SCAN_WINDOW_START,
                        },
                      },
                      onSuccessCallback: async () => {
                        addToast({
                          type: "success",
                          message: "Successfully updated scan window.",
                        });
                        await sleep(1500);
                        resetUpdateCustomer();
                      },
                      onErrorCallback: (error) =>
                        addToast({
                          type: "error",
                          message: `Failed to schedule scanners - ${error}`,
                        }),
                    });
                  }
                }}
                disabled={queryStatus !== "idle"}
                queryStatus={queryStatus}
              />
            </Flex>
            <Flex w100 column gap="8px">
              <Flex>
                <LabelRegular>End scanning at</LabelRegular>
              </Flex>
              <Dropdown
                variant="border"
                size="medium"
                value={
                  me?.customer.asm_config?.scan_window_end
                    ? {
                        label: me?.customer.asm_config?.scan_window_end,
                        value: me?.customer.asm_config?.scan_window_end,
                      }
                    : {
                        label: DEFAULT_SCAN_WINDOW_END,
                        value: DEFAULT_SCAN_WINDOW_END,
                      }
                }
                options={Array.from({ length: 24 }, (_, hour) => {
                  return {
                    label: `${hour.toString().padStart(2, "0")}:00`,
                    value: `${hour.toString().padStart(2, "0")}:00`,
                  };
                })}
                onChange={(opt) => {
                  if (opt?.value) {
                    updateCustomer({
                      customerId: me?.customer.id || 0,
                      customerData: {
                        asm_config: {
                          custom_user_agent:
                            me?.customer.asm_config?.custom_user_agent || "",
                          scan_window_end:
                            (opt?.value as string) || DEFAULT_SCAN_WINDOW_END,
                          scan_window_start:
                            me?.customer.asm_config?.scan_window_start ||
                            DEFAULT_SCAN_WINDOW_START,
                        },
                      },
                      onSuccessCallback: async () => {
                        addToast({
                          type: "success",
                          message: "Successfully updated scan window.",
                        });
                        await sleep(1500);
                        resetUpdateCustomer();
                      },
                      onErrorCallback: (error) =>
                        addToast({
                          type: "error",
                          message: `Failed to schedule scanners - ${error}`,
                        }),
                    });
                  }
                }}
                disabled={queryStatus !== "idle"}
                queryStatus={queryStatus}
              />
            </Flex>
          </Flex>
        </Flex>
        <SeparatorHorizontal />
        <Flex column padding="0 24px" gap="16px">
          <HeaderSubBold>Overview</HeaderSubBold>
          <BodyRegular style={{ color: theme.black800 }}>
            As part of its attack surface management, WASP performs scheduled
            vulnerability scans on your assets. To minimize disruption and avoid
            unnecessary alerts in your monitoring systems, users can define
            specific scan windows and intervals—such as limiting scans to
            off-peak hours (e.g., 10 PM – 6 AM).
          </BodyRegular>
        </Flex>
        <SeparatorHorizontal />
        <Flex column padding="0 24px" gap="16px">
          <HeaderSubBold>Scanners Affected</HeaderSubBold>
          <BadgesLine
            badgeMaxWidth="100%"
            badges={[
              "Vulnerability Scanner 1",
              "Vulnerability Scanner 2",
              "Light Scanner",
              "Wordpress Scanner",
              "Vulnerable JS Packages Scanner",
              "API Inspector",
            ]}
          />
        </Flex>
        <SeparatorHorizontal />
      </Flex>
    </RightPane>
  );
};

import React, { useContext, useRef, useState } from "react";
import { RightPane } from "../../../components/elements/rightPane/RightPane";
import { Flex } from "../../../components/layouts/flex/Flex";
import {
  BodyRegular,
  HeaderSecondary,
  HeaderSubBold,
  LabelRegular,
} from "../../../components/elements/typography/Typography";
import { SeparatorHorizontal } from "../../../components/elements/separators/SeparatorHorizontal";
import { Switch } from "../../../components/elements/switch/Switch";
import { ThemeContext } from "styled-components";
import {
  CustomerContext,
  useApiUpdateCustomer,
} from "../../../hooks/queries/customersContext";
import { Me } from "../../../types/Me";
import { TextButton } from "../../../components/elements/button/text/TextButton";
import useToastContext from "../../../hooks/toastHook";
import { useInfiniteScroll } from "../../../hooks/utilsHooks";
import { Loading } from "../../../components/elements/loading/Loading";
import { CustomerLog } from "../../../types/CustomerLog";
import { useApiCustomerLogPaging } from "../../../hooks/queries/customerLogsContext";
import { CustomerLogCard } from "./CustomerLogCard";
import { InputTextWithLeftAddon } from "../../../components/elements/input/textInput/InputTextWithLeftAddon";
import {
  DEFAULT_SCAN_WINDOW_END,
  DEFAULT_SCAN_WINDOW_START,
} from "../../../types/Customer";

type Props = {
  onClose: () => void;
  updatePolicyMutate: (customerContext: CustomerContext) => void;
  me: Me;
};

export const ScannerManagementPane = (props: Props) => {
  const { onClose, updatePolicyMutate: updatePolicy, me } = props;
  const [showUserAgent, setShowUserAgent] = useState<boolean>(
    !!me.customer.asm_config?.custom_user_agent
  );
  const [customUserAgent, setUserAgent] = useState<string | undefined>(
    !!me.customer.asm_config?.custom_user_agent
      ? me.customer.asm_config?.custom_user_agent
      : undefined
  );
  const theme = useContext(ThemeContext);
  const addToast = useToastContext();
  const { mutate: updateCustomer } = useApiUpdateCustomer();
  const observerElemForFetchPage = useRef(null);
  const {
    data: customerLogs,
    isFetchingNextPage,
    fetchNextPage,
    hasNextPage,
  } = useApiCustomerLogPaging(
    {
      field:
        "is_asm_enabled,disabled_scanners,auto_scan_new_assets,asm_config,scanners",
    },
    true
  );

  useInfiniteScroll(
    observerElemForFetchPage,
    hasNextPage || false,
    fetchNextPage
  );

  const getCustomerLogs = (): CustomerLog[] => {
    return customerLogs?.pages.map((page) => page?.results || []).flat() || [];
  };

  return (
    <RightPane onClose={onClose} width="550px">
      <Flex column gap="24px" padding="24px">
        <Flex column>
          <LabelRegular style={{ color: theme.black600 }}>
            Scanning Policy
          </LabelRegular>
          <HeaderSecondary>Scanners Management</HeaderSecondary>
        </Flex>
      </Flex>
      <SeparatorHorizontal />
      <Flex column gap="24px" padding="24px">
        <Flex column gap="18px">
          <HeaderSubBold>General</HeaderSubBold>
          <Flex justify="between" align="center" gap="24px" w100>
            <BodyRegular>Enable Attack Surface Mapping (ASM)</BodyRegular>
            <Switch
              checked={!!me.customer.is_asm_enabled}
              dataTestId="enable-asm-switch"
              disabled={!me?.can_manage_customer}
              onChange={(check) => {
                updatePolicy({
                  customerId: me.customer.id,
                  customerData: {
                    is_asm_enabled: check,
                  },
                });
              }}
            />
          </Flex>

          <Flex justify="between" align="center" gap="24px" w100>
            <BodyRegular>Auto scan new assets</BodyRegular>
            <Switch
              checked={!!me.customer.auto_scan_new_assets}
              dataTestId="auto-scan-switch"
              disabled={!me?.can_manage_customer}
              onChange={(check) => {
                updatePolicy({
                  customerId: me.customer.id,
                  customerData: {
                    auto_scan_new_assets: check,
                  },
                });
              }}
            />
          </Flex>
        </Flex>
        <HeaderSubBold>User Agent</HeaderSubBold>
        <Flex column gap="18px">
          <Flex justify="between" align="center" gap="24px" w100>
            <BodyRegular>Use custom User-Agent</BodyRegular>
            <Switch
              checked={!!me.customer.asm_config?.custom_user_agent}
              dataTestId="user-agent-switch"
              disabled={!me?.can_manage_customer || !me.customer.is_asm_enabled}
              onChange={(check) => {
                setShowUserAgent(check);
                if (!check) {
                  updateCustomer({
                    customerData: {
                      asm_config: {
                        custom_user_agent: "",
                        scan_window_end:
                          me.customer.asm_config?.scan_window_end ||
                          DEFAULT_SCAN_WINDOW_END,
                        scan_window_start:
                          me.customer.asm_config?.scan_window_start ||
                          DEFAULT_SCAN_WINDOW_START,
                      },
                    },
                    customerId: me.customer.id,
                    onSuccessCallback: () =>
                      addToast({
                        type: "success",
                        message: "You are not using a custom User-Agent.",
                      }),
                    onErrorCallback: (err) =>
                      addToast({
                        type: "error",
                        message: `Failed to update User-Agent: ${err}`,
                      }),
                  });
                }
              }}
            />
          </Flex>
          {showUserAgent && (
            <Flex w100>
              <InputTextWithLeftAddon
                width={"100%"}
                leftText="User Agent: "
                placeholder="Custom User Agent"
                value={customUserAgent}
                onChange={(e) => setUserAgent(e.target.value)}
                disabled={
                  !me?.can_manage_customer || !me.customer.is_asm_enabled
                }
              />
              <TextButton
                label={"Save"}
                size="medium"
                disabled={
                  !me?.can_manage_customer || !me.customer.is_asm_enabled
                }
                onClick={() => {
                  if (customUserAgent) {
                    updateCustomer({
                      customerData: {
                        asm_config: {
                          custom_user_agent: customUserAgent,
                          scan_window_end:
                            me.customer.asm_config?.scan_window_end ||
                            DEFAULT_SCAN_WINDOW_END,
                          scan_window_start:
                            me.customer.asm_config?.scan_window_start ||
                            DEFAULT_SCAN_WINDOW_START,
                        },
                      },
                      customerId: me.customer.id,
                      onSuccessCallback: () =>
                        addToast({
                          type: "success",
                          message: `User-Agent changed: ${customUserAgent}`,
                        }),
                      onErrorCallback: (err) =>
                        addToast({
                          type: "error",
                          message: `Failed to update User-Agent: ${err}`,
                        }),
                    });
                  } else {
                    addToast({
                      type: "error",
                      message: `No User-Agent provided.`,
                    });
                  }
                }}
              />
            </Flex>
          )}
        </Flex>
      </Flex>
      <SeparatorHorizontal />
      {!!me?.can_manage_customer ? (
        <Flex column gap="24px" padding="24px">
          <HeaderSubBold>Activity Logs</HeaderSubBold>
          <Flex
            column
            gap="24px"
            style={{ height: "calc(100vh - 420px)", overflow: "scroll" }}
          >
            {getCustomerLogs().map((customerLog, i) => (
              <CustomerLogCard
                key={`customer-log-${i}`}
                customerLog={customerLog}
              />
            ))}
            <Flex
              justify="center"
              className="loader"
              ref={observerElemForFetchPage}
            >
              {isFetchingNextPage ? (
                <Flex column justify="center">
                  <Loading />
                </Flex>
              ) : null}
            </Flex>
          </Flex>
        </Flex>
      ) : (
        <> </>
      )}
    </RightPane>
  );
};
